<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <form action="#" v-if="vmCustomReportFormData && Object.keys(vmCustomReportFormData).length > 0">
              <div class="form-row">

                  <div class="col-md-12 mb-3">
                    <label for="validationcust_report_name">
                      {{cvCustReportNameLabel}}</label
                    >
                    <input
                      v-model="vmCustomReportFormData.cust_report_name"
                      type="text"
                      class="form-control"
                      required
                    />
                </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationcust_report_description">
                      {{cvCustReportDescriptionLabel}}</label
                    >
                  <textarea v-model="vmCustomReportFormData.cust_report_des" type="text" class="form-control" required ></textarea>
                </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationcust_report_query">
                      {{cvCustReportQueryLabel}}</label>
                  <textarea v-model="vmCustomReportFormData.cust_report_query" type="text" class="form-control" required ></textarea>
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="customReportEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<style scoped>
textarea {
  min-height: 150px;
  line-height: 27px !important;
}
</style>
<script>
import { CustomReports } from "../../../FackApi/api/CustomReport"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "CustomReportEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propCustomReportObj: {
      type: Object,
      default: function () {
        return {
          "cust_report_name": "",
          "cust_report_id": "",
          "cust_report_des": "",
          "cust_report_query": ""
        }
      }
    }
  },
  data () {
    return {
      apiName: "custom_report_edit",
      cvCardTitle: "Edit Custom Report",
      cvSubmitBtn: "Edit",
      cvCustReportNameLabel: "Report name",
      cvCustReportDescriptionLabel: "Report Description",
      cvCustReportQueryLabel: "Report Query",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "Default",
      toastTitle: "CustomReport Updated",
      vmCustomReportFormData: {}
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.customReportView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmCustomReportFormData) {
          if (!this.vmCustomReportFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * customReportView
     */
    async customReportView () {
      try {
        if (this.propOpenedInModal) {
          this.vmCustomReportFormData = this.propCustomReportObj
        }
        else {
          let customReportId = this.$route.params.cust_report_id
          let customReportViewResp = await CustomReports.customReportView(this, customReportId)

          if (customReportViewResp && customReportViewResp.resp_status) {
            this.vmCustomReportFormData = customReportViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at customReportView() and Exception:", err.message)
      }
    },
    /**
     * customReportEdit
     */
    async customReportEdit () {
      try {
        let custReportQuery = this.vmCustomReportFormData.cust_report_query ? this.vmCustomReportFormData.cust_report_query.toLowerCase() : null
        if (!custReportQuery) {
          this.toastMsg = "Missing Query"
          this.toastVariant = "danger"
          this.showToast = true
          return false
        }

        if (custReportQuery && !custReportQuery.includes("select") &&
          (custReportQuery.includes("update") ||
            custReportQuery.includes("alter") ||
            custReportQuery.includes("delete")
          )
        ) {
          // only Select query is allowed
          this.toastMsg = "Only SELECT Query operation is allowed. Other Operations like UPDATE, DELETE etc are not allowed"
          this.toastVariant = "danger"
          this.showToast = true
          return false
        }

        this.cvLoadingStatus = true
        let customReportAddResp = await CustomReports.customReportEdit(this, this.vmCustomReportFormData)
        await ApiResponse.responseMessageDisplay(this, customReportAddResp)

        if (customReportAddResp && !customReportAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseCustomReportEditModal", this.vmCustomReportFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at customReportEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
